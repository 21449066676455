
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'CounterComponent',
  props: {
    counter: {
      type: Number,
      required: true,
    },
    decrement: {
      type: Function as PropType<() => void>,
      required: true,
    },
    increment: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
})
