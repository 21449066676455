import { render, staticRenderFns } from "./Counter.vue?vue&type=template&id=66d297a0"
import script from "./Counter.vue?vue&type=script&lang=ts"
export * from "./Counter.vue?vue&type=script&lang=ts"
import style0 from "./Counter.vue?vue&type=style&index=0&id=66d297a0&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FoundationsUiIcon: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/foundations/ui-icon/ui-icon.vue').default,AtomsCtaButton: require('/vercel/path0/node_modules/@aprildirect/april-direct-ui/lib/components/atoms/cta-button/cta-button.vue').default})
